import { Map } from 'immutable'
import * as cashAPI from 'lib/api/cash'
import { requestHandler } from 'lib'
const { createRedux } = requestHandler
const initialState = Map({})
const handler = {}

handler.getBalance = {
  payloadCreator: cashAPI.getBalance
}

handler.getBankAccount = {
  payloadCreator: cashAPI.getBankAccount
}

handler.getCompany = {
  options: {
    response: {
      error: {
        ignore: [404] // (array or boolean) default: false
      }
    }
  },
  payloadCreator: cashAPI.getCompany
}

handler.createCompany = {
  options: { debounce: { wait: 500 } },
  payloadCreator: cashAPI.createCompany
}

handler.updateCompany = {
  options: { debounce: { wait: 500 } },
  payloadCreator: cashAPI.updateCompany
}

handler.getTaxinvoice = {
  payloadCreator: cashAPI.getTaxinvoice
}

handler.createBankAccount = {
  options: { debounce: { wait: 500 } },
  payloadCreator: cashAPI.createBankAccount
}

handler.deleteBankAccount = {
  options: { debounce: { wait: 500 } },
  payloadCreator: cashAPI.deleteBankAccount
}

handler.createWithdraw = {
  options: { debounce: { wait: 500 } },
  payloadCreator: cashAPI.createWithdraw
}

handler.getWithdraw = {
  payloadCreator: cashAPI.getWithdraw
}

handler.transfer = {
  options: { debounce: { wait: 1500 } },
  payloadCreator: cashAPI.transfer
}

handler.getRechargeHistory = {
  payloadCreator: cashAPI.getRechargeHistory
}

handler.getBalanceHistory = {
  payloadCreator: cashAPI.getBalanceHistory
}

handler.getSubscribes = {
  payloadCreator: cashAPI.getSubscribes
}

handler.createSubscribe = {
  payloadCreator: cashAPI.createSubscribe
}

handler.getPayments = {
  payloadCreator: cashAPI.getPayments
}

handler.createPayment = {
  options: { debounce: { wait: 1500 } },
  payloadCreator: cashAPI.createPayment
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
