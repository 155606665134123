import axios from 'axios'
import qs from 'qs'

export const getBalance = (params = {}) => {
  const queryString = qs.stringify(params)
  const uri = `/cash/v1/balance${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}

export const getBankAccount = () => {
  const uri = `/cash/v1/bank/accounts`
  return axios.get(uri)
}

export const getCompany = (params = {}) => {
  const uri = `/cash/v1/company`
  return axios.get(uri)
}

export const createCompany = (params = {}) => {
  const uri = `/cash/v1/company`
  return axios.post(uri, params)
}

export const updateCompany = (params = {}) => {
  const uri = `/cash/v1/company`
  return axios.put(uri, params)
}

export const getTaxinvoice = (params = {}) => {
  const queryString = qs.stringify(params)
  const uri = `/cash/v1/taxinvoice${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}

export const createBankAccount = params => {
  const uri = `/cash/v1/bank/accounts`
  return axios.post(uri, params)
}

export const deleteBankAccount = () => {
  const uri = `/cash/v1/bank/accounts`
  return axios.delete(uri)
}

export const createWithdraw = amount => {
  const uri = `/cash/v1/withdraw`
  return axios.post(uri, { amount })
}

export const getWithdraw = (params = {}) => {
  const queryString = qs.stringify(params)
  const uri = `/cash/v1/withdraw${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}

export const transfer = (params = {}) => {
  const { targetAccountId, amount } = params
  const uri = `/cash/v1/transfer`
  return axios.post(uri, { targetAccountId, amount })
}

export const getRechargeHistory = (params = {}) => {
  const queryString = qs.stringify(params)
  const uri = `/cash/v1/recharge-history${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}

export const getBalanceHistory = (params = {}) => {
  const queryString = qs.stringify(params)
  const uri = `/cash/v1/balance-history${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}

export const getSubscribes = (params = {}) => {
  const queryString = qs.stringify(params)
  const uri = `/cash/v1/subscribes${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}

export const createSubscribe = (params = {}) => {
  const uri = `/cash/v1/subscribes`
  return axios.post(uri, params)
}

export const getPayments = () => {
  const uri = `/cash/v1/payment`
  return axios.get(uri)
}

export const createPayment = payload => {
  const uri = `/cash/v1/payment`
  return axios.post(uri, payload)
}
