import axios from 'axios'
// import qs from 'qs'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

// export const yourApiNameHere = (params = {}) => {
//   const searchParams = omitBy(params, isUndefined)
//   const query = qs.stringify(searchParams)
//   const uri = `/payment/v1/yourPathHear${query ? '?' + query : ''}`
//   return axios.get(uri)
// }

export const createKakaoClientSecret = () => {
  const uri = `/payment/v1/setups/kakaopay`
  return axios.post(uri)
}

export const createNaverClientSecret = () => {
  const uri = `/payment/v1/setups/naverpay`
  return axios.post(uri)
}

export const createKrCardClientSecret = () => {
  const uri = `/payment/v1/setups/card`
  return axios.post(uri)
}

export const setupConfirm = payload => {
  const { setupIntent, setupIntentClientSecret } = payload
  const uri = `/payment/v1/setups/confirm`
  return axios.post(uri, {
    setup_intent: setupIntent,
    setup_intent_client_secret: setupIntentClientSecret
  })
}

export const getPayments = () => {
  const uri = `/payment/v1/payment-methods`
  return axios.get(uri)
}

export const recharge = payload => {
  const { amount, billingKey, paymentMethod } = payload
  const uri = `/payment/v1/recharge`
  return axios.post(
    uri,
    omitBy({ amount, billingKey, paymentMethod }, isUndefined)
  )
}

export const deletePayment = payload => {
  const { billingKey } = payload
  const uri = `/payment/v1/payment-methods/${billingKey}`
  return axios.delete(uri)
}
